import { css, styled } from 'styled-components'
import { Link } from 'react-router-dom'
// import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import HighlightRoundedIcon from '@mui/icons-material/HighlightRounded'
import BoltIcon from '@mui/icons-material/Bolt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faParachuteBox } from '@fortawesome/free-solid-svg-icons'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'

import PickAxe from 'src/pages/assets/pick.svg?react'
import discordIcon from 'src/pages/assets/logos/discord.svg'
import twitterIcon from 'src/pages/assets/logos/x.svg'
import telegramIcon from 'src/pages/assets/logos/telegram.svg'
// import githubIcon from 'src/pages/assets/logos/github.svg'
import { BREAKPOINTS, COLORS, FONTS } from 'src/shared/constants'
import { applyGlowFilter } from 'src/shared/styles'
import { hexToRgb } from 'src/shared/utils'
import { LINKS } from 'src/settings'
import { A, BUTTON_HOVER_STYLES, IconWithCount } from 'src/shared/components'
import { useCommittedEtchings } from 'src/etching'
import { ACCOUNT_DETAILS_TABS } from 'src/account'
import { useWalletContext } from 'src/wallet'
import { useIsMobile } from 'src/shared/hooks'
import { ChainSelector } from 'src/web3'

import { ROUTES, buildAccountDetailsUrl } from '../constants'

interface Props {
  onNavItemClick: () => void
  isHamburgerMenu?: boolean
}

export function NavLinks({ onNavItemClick, isHamburgerMenu }: Props) {
  const isMobile = useIsMobile()
  const { runesAddress } = useWalletContext()
  const { committedEtchingsCount } = useCommittedEtchings()

  const hideOnDesktop = isHamburgerMenu && !isMobile

  return (
    <>
      {isMobile && <ChainSelectorWrapper $hideOnDesktop={hideOnDesktop} />}
      <ReactRouterLink to={ROUTES.home} onClick={onNavItemClick} $hideOnDesktop={hideOnDesktop}>
        <MarketsIcon />
        Markets
      </ReactRouterLink>

      <ReactRouterLink to={ROUTES.mint} onClick={onNavItemClick} $hideOnDesktop={hideOnDesktop}>
        <MintIcon />
        Mint
      </ReactRouterLink>

      {runesAddress && committedEtchingsCount > 0 && (
        <ReactRouterLink
          to={`${buildAccountDetailsUrl(
            runesAddress.addrString,
            ACCOUNT_DETAILS_TABS.commitedEtches
          )}`}
          onClick={onNavItemClick}
          $hideOnDesktop={hideOnDesktop}
        >
          <IconWithCount count={committedEtchingsCount}>
            <RevealEtchIcon /> Reveal
          </IconWithCount>
        </ReactRouterLink>
      )}
      <ExternalLinkWrapper href={'https://airdrop.lfgmarket.io/'} $hideOnDesktop={!isHamburgerMenu}>
        <AirdropIcon icon={faParachuteBox} />
        Airdrop Tool
      </ExternalLinkWrapper>
      <ExternalLinkWrapper href={'https://burn.mystic.com'} $hideOnDesktop={!isHamburgerMenu}>
        <PickAxeIcon />
        Miner
      </ExternalLinkWrapper>
      {runesAddress && (
        <ReactRouterLink
          to={`${buildAccountDetailsUrl(runesAddress.addrString)}`}
          onClick={onNavItemClick}
          $hideOnDesktop={hideOnDesktop}
        >
          <RunesIcon>▣</RunesIcon>
          My Runes
        </ReactRouterLink>
      )}
      {!isMobile && <ChainSelectorWrapper $hideOnDesktop={hideOnDesktop} />}
      {/* <ReactRouterLink to={ROUTES.demoWasm}>Demo Wasm</ReactRouterLink> */}
      {/* <ReactRouterLink to={ROUTES.orders} onClick={onNavItemClick}>Orders</ReactRouterLink> */}
    </>
  )
}

const ReactRouterLink = styled(Link)<{ $hideOnDesktop?: boolean }>`
  color: ${COLORS.buttons.accent};
  display: flex;
  gap: 3px;

  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  ${BUTTON_HOVER_STYLES}
  ${applyGlowFilter({ hoverColor: COLORS.white })}
  ${(props) =>
    props.$hideOnDesktop &&
    css`
      display: none;
    `}
`

export function ExternalLinks({ className }: { className?: string }) {
  return (
    <MenuContainer className={className}>
      <Links>
        <LeftContent>
          {/* <ReactRouterLink to={ROUTES.about}>About</ReactRouterLink>
          <ReactRouterLink to={ROUTES.faq}>FAQ</ReactRouterLink>
          <ReactRouterLink to={ROUTES.contact}>Contact Us</ReactRouterLink> */}
          <Copywrite>© 2024 Mystic</Copywrite>
        </LeftContent>
        <RightContent>
          <A href={LINKS.telegram}>
            <TelegramLogoImage src={telegramIcon} alt='telegram' />
          </A>
          <A href={LINKS.discord}>
            <LogoImage src={discordIcon} alt='discord' />
          </A>
          <A href={LINKS.twitter}>
            <LogoImage src={twitterIcon} alt='twitter' />
          </A>
          {/* <A href={LINKS.github}>
            <LogoImage src={githubIcon} alt='github' />
          </A> */}
        </RightContent>
      </Links>
    </MenuContainer>
  )
}

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  padding: 20px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: 10px;
  }
`

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 15px;
  }
`

const Copywrite = styled.div`
  font-family: ${FONTS.headers};
  ${applyGlowFilter({})}
  color: ${hexToRgb(COLORS.white, 0.8)};
  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: 15px;
  }
`

const LogoImage = styled.img`
  ${applyGlowFilter({ useMoreGlow: true })}
  height: 27px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    height: 20px;
  }
`

const TelegramLogoImage = styled(LogoImage)`
  height: 36px;
  margin-top: -5px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    margin-top: -3px;
    height: 25px;
  }
`

const RevealEtchIcon = styled(HighlightRoundedIcon)`
  height: 20px;
  margin-top: -2px;
`

const LeftContent = styled.div`
  display: flex;
  gap: 10px;
`

const RightContent = styled.div`
  display: flex;
  gap: 15px;
`

const MarketsIcon = styled(SwapHorizIcon)`
  height: 30px;
  width: 30px;
  margin-right: 2px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    height: 22px;
    width: 23px;
    margin-left: -2px;
  }
`

const PickAxeIcon = styled(PickAxe)`
  height: 20px;
  margin-top: 2px;
  fill: ${COLORS.white};
`

const MintIcon = styled(BoltIcon)`
  margin-left: -3px;
`

// const EtchIcon = styled(AutoAwesomeIcon)`
//   height: 20px;
// `

const AirdropIcon = styled(FontAwesomeIcon)`
  height: 16px;
  padding-top: 3px;
  padding-right: 2px;
`

const RunesIcon = styled.div`
  padding-left: 3px;
  padding-right: 5px;
  @media (max-width: ${BREAKPOINTS.medium}) {
    margin-left: -2px;
  }
`

const ExternalLink = styled(A)`
  font-weight: 500;
  display: flex;
  gap: 5px;
`

const ExternalLinkWrapper = styled(ExternalLink)<{ $hideOnDesktop?: boolean }>`
  font-weight: 500;
  text-transform: uppercase;
  align-items: center;
  ${applyGlowFilter({ hoverColor: COLORS.white })}
  ${(props) =>
    props.$hideOnDesktop &&
    css`
      display: none;
    `}
`

const ChainSelectorWrapper = styled(ChainSelector)<{ $hideOnDesktop?: boolean }>`
  ${(props) =>
    props.$hideOnDesktop &&
    css`
      display: none;
    `}
`
